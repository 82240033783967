<template>
  <div id="Page-Index" class="main-wrap">
		<slider ref="slider" v-if="$root.CONFIG && $root.CONFIG.index && $root.CONFIG.index.slider" animation="normal" :height="sliderHeight+'px'" :interval="5000" :control-btn="false">
			<slider-item v-for="item in $root.CONFIG.index.slider" :key="item.id" >
				<a v-if="item.linkUrl" :href="item.linkUrl" target="_blank"><CoverWrap unanimation :width="(appWidth-90)+'px'" :height="(sliderHeight-60)+'px'" :src="item.path+'?x-oss-process=image/resize,w_2000'"/></a>
				<CoverWrap v-else unanimation :width="(appWidth-90)+'px'" :height="(sliderHeight-60)+'px'" :src="item.path+'?x-oss-process=image/resize,w_2000'"/>
			</slider-item>
			<div class="swiper-pagination" slot="pagination"></div>
		</slider>
		
		<div class="mt-20"></div>
		<div class="mt-20"></div>
		<div class="mt-20"></div>
		
		<div class="list_wrap">
			<div class="list flex between middle wrap" >
				<div class="item" v-for="item in $root.CONFIG.index.list" :key="item.id" @click="toListPage(item)" :class="{unactive:!item.linkUrl}">
					<div class="cover"><img :src="item.path.indexOf('.gif')>-1?item.path:(item.path+'?x-oss-process=image/resize,w_600')"/></div>
					<!-- <div class="etitle">APD No.{{item.id}}</div>
					<div class="title" v-if="$root.lang=='CN'">第{{$root.n2c(item.id)}}届亚太设计年鉴</div>
					<div class="title" v-if="$root.lang=='EN'">Asia-Pacific Design {{item.id}}</div> -->
					<div class="etitle" v-if="item.title1">{{item.title1}}</div>
					<div class="title" v-if="item.title2">{{L(item.title2)}}</div>
				</div>
				
				<div class="item empty" v-for="(item,index) in 10" :key="index"></div>
			</div>
		</div>
  </div>
</template>

<script>
import { Slider, SliderItem } from 'vue-easy-slider'
import CoverWrap from '/src/components/CoverWrap.vue';
var timer;
export default {
  name: 'Page-Index',
  components: {
    Slider, SliderItem,
		CoverWrap
  },
  data(){
    return {
			// awardStageList:[],
			appWidth:1832,
			sliderHeight:1832-90
    };
  },
  async mounted () {
		// this.awardStageList = this.$root.awardStageList.filter(item=>item.status_index == 1);
		window.addEventListener("scroll", this.runScroll); 
		this.runScroll();
  },
	destroyed() {
		window.removeEventListener('scroll', this.runScroll)
	},
  methods: {
		
		runScroll(){
			if(timer){clearTimeout(timer);}
			timer = setTimeout(this.handleScroll,100);
			
		},
		handleScroll(){
			this.appWidth = document.getElementById("Page-Index").clientWidth;
			this.sliderHeight =  (this.appWidth - 90) * 0.5 + 60;
			// this.$refs.slider.next();
		},
		toListPage(item){
			if(!item.linkUrl){
				return ;
			}
			window.open(item.linkUrl);
		}
  }
}
</script>

<style scoped lang="less">
#Page-Index{
	padding-bottom:20px;
	// min-width: 1024px;
	// overflow: auto;
	img{max-width:100%;}
	.list_wrap{
		width: 100%;
		overflow-x: auto;
	}
	.list{
		min-width:1024px;
		padding:0 90px;
		width: 100%;
		.item{
			// margin-right:20px;
			cursor: pointer;
			font-size:22px;
			line-height: 1.4;
			text-align: center;
			width:calc((100%  - 34px) / 3);
			max-width: calc((1440px - 90px - 180px - 34px) / 3);
			min-width: calc((1024px - 30px - 180px - 34px) / 3);
			margin-bottom:40px;
			.title,.etitle{font-size:18px;}
			&.empty{
				margin-bottom:0px;
				height:0;
			}
			.cover{
				margin-bottom:10px;
			}
			&:last-child{
				margin-right:0;
			}
		}
	}
}
</style>